














































import { formatDate, parseDate } from '@/shareds/date/date-utils'
import { Vue, Component, Ref, PropSync, Watch, Prop } from 'vue-property-decorator'

@Component
export default class RangeDatePicker extends Vue {
	@PropSync('value', {
		type: [Array, String],
		default: () => [],
	})
	datas!: string[] | string | null
	@Ref() menu!: { save: (value: any) => void }
	@Prop() inicial!: any
	@Prop() final!: any

	datasFormatadas: (string | null)[] = [null, null]

	mostrar = false

	ultimoCampoFocado = 0

	parseDate = parseDate

	@Watch('datas', { immediate: true })
	onChangeDate(datas: string[] | string | null) {
		this.datasFormatadas = !Array.isArray(datas)
			? [formatDate(datas), null]
			: [...datas].sort((data1, data2) => {
				if (!data1) return 1
				return data1.localeCompare(data2)
			}).map(formatDate)
	}

	recomputarDatas() {
		this.datasFormatadas = [...this.datasFormatadas].sort(
			(dataA, dataB) => {
				if (dataA === dataB) return 0
				if (!dataA) return 1
				if (!dataB) return -1
				return 0
			})
			.map(data => data || null)
		const datas = this.datasFormatadas.map(parseDate)
		this.$emit('input', datas)
	}
}
