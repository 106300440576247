




















































































































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import { CancelToken } from 'axios'
import { Page, Pageable } from '@/models'
import { nextTick } from '@/shareds/utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FiltroDeDocumentosFiscais} from '@/models/relatorios/FiltroDeDocumentosFiscais'
import moment from 'moment'
import { formatDate } from '@/shareds/date/date-utils'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojas.vue'
import { FindRelatorioUseCase } from '@/usecases'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import { DocumentoFiscal, TotalizadoresDeDocumentosFiscais } from '@/models/fiscal/DocumentoFiscal'
import DialogoDeFiltroDeRelatorioDeDocumentos from '@/components/relatorio/DialogoDeFiltroDeRelatorioDeDocumentos.vue'
import { formatarMoeda } from '@/shareds/formatadores'

@Component({
	components: {
		DataTableDeCrudPaginado,
		SeletorDeLojasDoUsuario,
		RangeDatePicker,
		DialogoDeFiltroDeRelatorioDeDocumentos,
	},
})
export default class TelaDeRelatoriosDeDocumentosFiscais extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeFiltros!: DialogoDeFiltroDeRelatorioDeDocumentos

	documentos: DocumentoFiscal[] = []
	findRelatorioUseCase = new FindRelatorioUseCase()
	datas = []
	carregando = false

	totalizadores: TotalizadoresDeDocumentosFiscais | null = null

	filtro: FiltroDeDocumentosFiscais = {
		lojaId: null,
		dataInicial: null,
		dataFinal: null,
		serieFiscal: '',
		ambientes: ['Produção'],
		notaInicial: null,
		notaFinal: null,
		tpnf: 'Entrada e Saída',
		exibirSomenteNotasValidas: false,
		somenteCompras:false,
	}

	headers = [
		{ text: 'Emissão', value: 'displayEntrada' },
		{ text: 'Número NFC-e/NF-e', value: 'nf' },
		{ text: 'Fornecedor', value: 'displayFornecedor' },
		{ text: 'Valor dos Produtos', value: 'displayValorProdutos' },
		{ text: 'ICMS', value: 'displayValorIcms' },
		{ text: 'IPI', value: 'displayIpi' },
		{ text: 'Desconto', value: 'displayDesconto' },
		{ text: 'Valor NFC-e/NF-e', value: 'displayValorNota' },
		{ text: 'CNPJ ou CPF', value: 'displayCnpjOuCpf' },
		{ text: 'Chave de acesso', value: 'chaveDeAcesso' },
		{ text: 'Tipo de Documento', value: 'displayTipoNf' },
	]

	pagina: Page<DocumentoFiscal> | null = null
	dataInvalida = false
	rangeData = 0

	limpeza = false

	get itensFormatados() {
		return this.documentos.map(documento => ({
			...documento,
			displayEntrada: !documento.entrada ? '-' : this.formatarStringToDataHora(documento.entrada),
			displayFornecedor: !documento.nomeFornecedor ? '-' : documento.nomeFornecedor,
			displayValorProdutos: !documento.valorProdutos ? '0,00' : formatarMoeda(documento.valorProdutos),
			displayValorIcms: !documento.valorIcms ? '0,00' : formatarMoeda(documento.valorIcms),
			displayIpi: !documento.ipi ? '0,00' : formatarMoeda(documento.ipi),
			displayDesconto: !documento.desconto ? '0,00' : formatarMoeda(documento.desconto),
			displayValorNota: !documento.valorNota ? '0,00' : formatarMoeda(documento.valorNota),
			displayCnpjOuCpf: !documento.cnpjOuCpf ? '-' : documento.cnpjOuCpf,
			displayTipoNf: this.displayTipoNf(documento.tipoDeNf),
		}))
	}

	displayChaveAcesso(value: string) {
		const recorte = value.slice(22,value.length)
		return '...' + recorte
	}

	displayTipoNf(tipo: number) {
		if(tipo == 1) return 'Saída'
		else return 'Entrada'
	}

	formatarStringToDataHora(dataString: string) {
		return moment(dataString).format('L HH:mm:ss')
	}

	get dataInicial() {
		return this.datas[0]
	}
	get dataFinal() {
		return this.datas[1]
	}

	async buscar(paginavel: Pageable, cancelToken: CancelToken) {
		await nextTick()
		try {
			this.carregando = true
			if (!this.filtro.lojaId) return []
			const pagina = await this.findRelatorioUseCase.listDocumentos({
				...this.filtrosFormatados,
				...paginavel,
			}, {cancelToken})
			this.documentos = pagina.content
			this.totalizadores = await this.findRelatorioUseCase.getTotalDeDocumentosFiscais({...this.filtrosFormatados}, {cancelToken});
			return pagina
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	calculaRangeData(){
		if(!this.datas[0] || !this.datas[1]) this.dataInvalida = true
		if(this.isReverseData()) {
			this.datas.reverse()
		}
		if(this.datas.length > 0) {
			const starts = moment(this.datas[0]+' 00:00:00','YYYY/MM/DD')
			let ends   = moment(this.datas[1]+' 00:00:00','YYYY/MM/DD')
			if(!this.datas[1]) {
				const now = new Date().toISOString().substring(0,10)
				ends = moment(formatDate(now) + ' 00:00:00','YYYY/MM/DD')
			}
			this.rangeData = Math.abs(starts.diff(ends,'days'))
			if(this.rangeData > 30) this.dataInvalida = true
		}
	}

	isReverseData() {
		if(!this.datas[0] || !this.datas[1]) return false
		if(this.datas[1] < this.datas[0]) return true
	}

	async aplicarFiltros(filtro: any) {
		this.calculaRangeData()
		
		if(this.limpeza == false) {
			if (!this.filtro.lojaId) AlertModule.setError('Selecione uma loja para prosseguir')
			if (!this.datas[0]) AlertModule.setError('Selecione um período')
			if (this.rangeData > 30) AlertModule.setError('Seleção inválida! Selecione um período de até 30 dias.')
		}

		if(!this.filtro.lojaId || !this.datas[0] || this.rangeData > 30) {
			this.filtro.lojaId = null
			this.datas = []
			this.dataTablePaginado.reiniciar()
		} 
		else {
			this.dataTablePaginado.reiniciar()
		}
		
		if(filtro.type != 'submit') {
			this.filtro = filtro
		}
		if(!this.dataInvalida)
			this.dataTablePaginado.reiniciar(this.filtrosFormatados)

		this.dialogoDeFiltros.ocultar()
	}

	get filtrosFormatados() {
		const dataInicial = this.gerarDataHora(this.datas[0], this.datas[0] || '00:00')
		const dataFinal = this.gerarDataHora(this.datas[1], this.datas[1] || '23:59')
		const novoFiltro = {
			lojaId: this.filtro.lojaId,
			dataInicial,
			dataFinal,
			serieFiscal: this.filtro.serieFiscal,
			ambientes: this.filtro.ambientes,
			notaInicial: this.filtro.notaInicial != '' ? this.filtro.notaInicial : null,
			notaFinal: this.filtro.notaFinal != '' ? this.filtro.notaFinal : null,
			tpnf: this.filtro.tpnf ? this.atualizarFiltroTpnf(this.filtro.tpnf) : null,
			exibirSomenteNotasValidas: this.filtro.exibirSomenteNotasValidas,
			somenteCompras: this.filtro.somenteCompras,
		}
		return novoFiltro
	}

	atualizarFiltroTpnf(value: string) {
		if(this.filtro.somenteCompras){
			return '0'
		}
		if (value === 'Entrada') {
			return '0'
		} else if(value === 'Saída') {
			return '1'
		} else {
			return null
		}
	}

	gerarDataHora(data?: string | null, hora?: string | null): string | undefined {
		return moment(`${data} ${hora}`,  'YYYY-MM-DD HH:mm').toISOString(true)
	}

	limparFiltros() {
		this.limpeza = true
		const filtroDefault: FiltroDeDocumentosFiscais = {
			lojaId: null,
			dataInicial: this.datas[0],
			dataFinal: this.datas[1],
			serieFiscal: '',
			ambientes: ["Produção"],
			notaInicial: null,
			notaFinal: null,
			tpnf: null,
			exibirSomenteNotasValidas: false,
			somenteCompras:false,
		}

		this.carregando = true
		this.filtro = filtroDefault
		this.documentos = []
		this.totalizadores = null
		this.carregando = false
		setTimeout(() => this.limpeza = false, 1000)
	}

	abrirFiltros() {
		this.dialogoDeFiltros.mostrar()
	}

@Watch('paginacao', { deep: true })
onChangePaginacao = this.buscar

}
