












































import { Vue, Component, Prop, Watch, PropSync, Ref } from 'vue-property-decorator'
import { FindLojaUseCase } from '@/usecases'
import { Loja, Page, Pageable } from '@/models'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component
export default class SeletorDeLojas extends Vue {
	@Ref() campo!: HTMLInputElement
	@PropSync('value') selecionado?: Loja | Loja[] | string | string[]
	@Prop({type: Array, default: () => []}) excludes!: string[]
	@Prop({ type: String }) idDaLojaSalva!: string | null
	@Prop({ type: String }) usuario?: string
	@Prop({ type: Boolean, default: false }) retornaObjetoLoja?: boolean
	@Prop({ type: Boolean, default: false }) listarLojasDoGrupoDoUsuario!: boolean
	@Prop({ type: String, default: null }) grupoEconomicoId!: string | null
	
	lojas: Loja[] = []
	busca: string | null = null
	findLojaUseCase = new FindLojaUseCase()
	cancelToken: CancelTokenSource | null = null
	selecionarIdUnico: string | null = null

	pageable: Pageable = {
		page: 0,
		size: 3,
	}
	
	pagina: Page<Loja> | null = null
	carregando = true

	created() {
		this.carregarSelecao()
	}

	@Watch('selecionado', { deep: true })
	onChangeSelecionado() {
		if(this.selecionado && typeof this.selecionado === 'object') {
			this.carregarSelecao()
		}	
	}

	extrairId(objeto: Loja | string): string {
		return typeof objeto === 'object'
			? objeto.id
			: objeto
	}

	async carregarSelecao() {
		if (!this.selecionado || typeof this.selecionado === 'object') {
			return await this.find()
		}
		
		if (this.cancelToken) {
			this.cancelToken.cancel('Requisição anterior cancelada')
		}

		this.cancelToken = axios.CancelToken.source()
		const ids: string[] = Array.isArray(this.selecionado)
			? (this.selecionado as (string | Loja)[]).map(this.extrairId)
			: [this.extrairId(this.selecionado)]
		try {
			this.carregando = true

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.pagina = await this.findLojaUseCase.find({
				ids,
				lojasDoGrupoDoUsuario: this.listarLojasDoGrupoDoUsuario,
			}, axiosConfig)
			this.pagina.content.forEach(loja => {
				this.lojas.push(loja)
			})
			
		} catch (error: any) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async find() {
		if (this.cancelToken) {
			this.cancelToken.cancel('Requisição anterior cancelada')
		}

		this.cancelToken = axios.CancelToken.source()
		try {
			this.carregando = true
			if(this.grupoEconomicoId != null){
				this.pagina = await this.findLojaUseCase.findLojasByGrupoEconomicoId(
					this.grupoEconomicoId, 
					{cancelToken: this.cancelToken.token})
			}else {
				this.pagina = await this.findLojaUseCase.find({
					page: this.pageable.page || 0,
					size: this.pageable.size,
					busca: this.busca || undefined,
					lojasDoGrupoDoUsuario: this.listarLojasDoGrupoDoUsuario,
				}, {
					cancelToken: this.cancelToken.token,
				})}

			this.pagina.content.forEach(loja => {
				if (this.lojas.some(({ id }) => loja.id === id)) return
				this.lojas.push(loja)
				this.selecionarIdUnico = loja.id;
			})
			if (this.lojas.length < 2 && this.selecionarIdUnico) {
				this.selecionado = ''
				this.$emit('input', this.retornaObjetoLoja ? this.lojas[0] : this.selecionarIdUnico)
			}

		} catch (error: any) {
			if (axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}


	@Watch('busca', { deep: true })
	onChangeBusca(busca: string, buscaAnterior: string) {
		this.pageable = {
			...this.pageable,
			page: 0,
		}
		if (busca === buscaAnterior) {
			this.carregando = false
			return
		}
		this.find()
	}

	async pageNext() {
		this.pageable.page = !this.pageable.page
			? 1
			: this.pageable.page += 1

		await this.find()
	}

	get lojasFiltradas() {
		return this.lojas
	}

	get carregouTodosAsLojas() {
		return this.pagina?.last || false
	}

	get noDataMessage() {
		if (this.carregando) {
			return 'Carregando...'
		}
		if (!this.busca) {
			return 'Digite para consultar...'
		}
		return '$vuetify.noDataText'
	}

	focus() {
		this.campo.focus()
	}

	limparLojas() {
		this.lojas = [] 
	}

	@Watch('grupoEconomicoId', { immediate: true })
	onGrupoEconomicoChange() {
		this.limparLojas()
		this.find() 
	}

}
