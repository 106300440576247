var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"disabled":_vm.carregando},on:{"submit":function($event){$event.preventDefault();return _vm.aplicarFiltros.apply(null, arguments)}}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('SeletorDeLojasDoUsuario',{attrs:{"prepend-icon":"mdi-store","label":"Loja","clearable":"","idDaLojaSalva":_vm.filtro.lojaId},model:{value:(_vm.filtro.lojaId),callback:function ($$v) {_vm.$set(_vm.filtro, "lojaId", $$v)},expression:"filtro.lojaId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('RangeDatePicker',{attrs:{"inicial":{
								label: 'Data Inicial',
								clearable: true,
							},"final":{
								label: 'Data Final',
								clearable: true,
							}},model:{value:(_vm.datas),callback:function ($$v) {_vm.datas=$$v},expression:"datas"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","type":"submit","loading":_vm.carregando,"disabled":_vm.carregando}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Aplicar ")],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"filled":"","disabled":_vm.carregando},on:{"click":_vm.abrirFiltros}},[_c('v-icon',[_vm._v(" mdi-filter ")]),_vm._v(" Filtros ")],1)],1)],1)],1)],1)],1),_c('v-card',[_c('DataTableDeCrudPaginado',{ref:"dataTablePaginado",attrs:{"headers":_vm.headers,"items":_vm.itensFormatados,"hideActions":"","hideCreate":"","footer-props":{ itemsPerPageOptions: [ 25, 40, 50 ] },"loading":_vm.carregando,"onBuscarPagina":_vm.buscar},scopedSlots:_vm._u([{key:"item.chaveDeAcesso",fn:function(ref){
							var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.displayChaveAcesso(item.chaveDeAcesso))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.chaveDeAcesso))])])]}},{key:"body.append",fn:function(ref){
							var headers = ref.headers;
return [(_vm.totalizadores != null)?_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex justify-space-around"},[_c('strong',[_vm._v(" Total Produtos: "+_vm._s(_vm.totalizadores.valorTotalProdutos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")]),_c('strong',[_vm._v(" Total ICMS: "+_vm._s(_vm.totalizadores.valorTotalIcms.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")]),_c('strong',[_vm._v(" Total IPI: "+_vm._s(_vm.totalizadores.valorTotalIpi.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")]),_c('strong',[_vm._v(" Total Desconto: "+_vm._s(_vm.totalizadores.valorTotalDesconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")]),_c('strong',[_vm._v(" Total NFC-e/NF-e: "+_vm._s(_vm.totalizadores.valorTotalNota.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")])])])]):_vm._e()]}}],null,true)})],1),_c('DialogoDeFiltroDeRelatorioDeDocumentos',{ref:"dialogoDeFiltros",attrs:{"carregando":_vm.carregando,"lojaId":_vm.filtro.lojaId,"filtro":_vm.filtro},on:{"buscar":_vm.aplicarFiltros,"limpar":_vm.limparFiltros},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }